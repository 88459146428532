/* eslint-disable quotes, ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export const REQUISITION_ATTRIBUTES_DRAFT_ENABLED = Object.freeze([
  'Identifier',
  'Project',
  'Template',
  'Entered By',
  'Created Date',
  'Draft Created Date',
  'Overall Status',
  'Held Reason(s)'
].map(v => ({ display: v, value: v })));

export const REQUISITION_ATTRIBUTES = Object.freeze([
  'Identifier',
  'Project',
  'Template',
  'Entered By',
  'Created Date',
  'Overall Status',
  'Held Reason(s)',
].map(v => ({ display: v, value: v })));

export const SAMPLE_ATTRIBUTES = Object.freeze([
  'Sample Identifier',
  'Collection Date',
  'Collected By',
  'Sample Received Date',
  'Sample Status',
  'Sample Type',
  'Sample Container Type',
  'Sample TAT (hours)',
  'Sample Group',
  'Sample Reject Reason'
].map(v => ({ display: v, value: v })));

export const PATIENT_ATTRIBUTES = Object.freeze([
  'Patient First Name',
  'Patient Middle Name',
  'Patient Last Name',
  'Patient MRN',
  'Patient Date of Birth',
  'Patient Sex',
  'Patient Ethnicity',
  'Patient Race',
  'Patient Phone Number',
  'Patient Email',
  'Patient Address 1',
  'Patient Address 2',
  'Patient City',
  'Patient State/Region/Province',
  'Patient Zip/Postal Code',
  'Patient Country',
  'Signed Unified Consent',
].map(v => ({ display: v, value: v })));

export const PROVIDER_ATTRIBUTES = Object.freeze([
  'Provider',
  'Provider NPI',
  'Provider Account',
  'Provider Account Group',
  'Sales Rep',
  'Provider Group',
  'Provider Group Hierarchy',
  'Secondary Provider',
  'Secondary Provider NPI',
  'Secondary Provider Account',
  'Additional Provider',
  'Additional Provider NPI',
  'Additional Provider Account',
].map(v => ({ display: v, value: v })));

export const PANELS_ATTRIBUTES = Object.freeze([
  'Panels',
  'Test Panel Codes'
].map(v => ({ display: v, value: v })));

export const STATUSES_ATTRIBUTES = Object.freeze([
  'Revised Report',
  'Incomplete Report',
  'Complete Report',
  'Correction Reason',
  'Signature Comment',
  'Req Form Verified By',
  'Req Form Verified Date'
].map(v => ({ display: v, value: v })));

export const BILLING_ATTRIBUTES = Object.freeze([
  'Bill To',
  'Primary Insurance',
  'Secondary Insurance',
  'Reimbursement Status',
  'Case Payer Scenario',
  'Total Charges',
  'Adjusted Charges',
  'Receipts',
  'Balance',
  'Posting Date',
  'Primary Insurance Plan Name',
  'Primary Insurance ID #',
  'Primary Insurance Subscriber #',
  'Primary Insurance Group #',
  'Primary Relationship to Insured',
  'Primary Name of Person Insured',
  'Primary Reimbursement Eligibility Verified',
  'Primary Authorization Number',
  'Primary Effective Start Date',
  'Primary Effective End Date',
  'Primary Number Allowed Tests',
  'Primary Contact Name',
  'Primary Contact Phone Number',
  'Primary Insurance First Bill Date',
  'Primary Insurance Payment Posting Date',
  'Primary Insurance Payment',
  "Primary Insured Date of Birth",
  "Primary Insured Email",
  "Primary Insured Street Address",
  "Primary Insured City",
  "Primary Insured State",
  "Primary Insured Zip Code",
  "Primary Insured Sex",
  'Secondary Insurance Plan Name',
  'Secondary Insurance ID #',
  'Secondary Insurance Subscriber #',
  'Secondary Insurance Group #',
  'Secondary Relationship to Insured',
  'Secondary Name of Person Insured',
  'Secondary Reimbursement Eligibility Verified',
  'Secondary Authorization Number',
  'Secondary Effective Start Date',
  'Secondary Effective End Date',
  'Secondary Number Allowed Tests',
  'Secondary Contact Name',
  'Secondary Contact Phone Number',
  'Secondary Insurance First Bill Date',
  'Secondary Insurance Payment Posting Date',
  'Secondary Insurance Payment',
  "Secondary Insured Date of Birth",
  "Secondary Insured Email",
  "Secondary Insured Street Address",
  "Secondary Insured City",
  "Secondary Insured State",
  "Secondary Insured Zip Code",
  "Secondary Insured Sex",
  'Patient Pay Name',
  'Patient Pay Email',
  'Patient Pay Phone Number',
  'Patient Pay Street',
  'Patient Pay City',
  'Patient Pay State',
  'Patient Pay Zip Code',
  'Patient First Bill Date',
  'Patient Payment Posting Date',
  'Patient Payment Amount',
  'Patient Balance',
  'Insurance Balance',
  'Facility Pay Name',
  'Facility Pay Email',
  'Facility Pay Phone Number',
  'Facility Pay Street',
  'Facility Pay City',
  'Facility Pay State',
  'Facility Pay Zip Code'
].map(v => ({ display: v, value: v })));

export const MEDICATION_ATTRIBUTES = Object.freeze([
  'Medication Information'
].map(v => ({ display: v, value: v })));

export const ICD_10_ATTRIBUTES = Object.freeze([
  'ICD-10 Codes'
].map(v => ({ display: v, value: v })));

export default Model.extend({
  identifier: attr('string'),
  createdAt: attr('date'),
  sampleCollectionDate: attr('date'),
  dateReceived: attr('date'),
  requisitionTemplateName: attr('string'),
  ownerName: attr('string'),
  submittedByName: attr('string'),
  sampleIdentifiers: attr('string'),
  sampleLabels: attr('string'),
  turnaroundTime: attr('string'),
  patientFirstName: attr('string'),
  patientLastName: attr('string'),
  patientDateOfBirth: attr('string'),
  patientGenderAb: attr('string'),
  physicianName: attr('string'),
  physicianNpi: attr('string'),
  salesAccountName: attr('string'),
  salesRepName: attr('string'),
  salesAccountGroup: attr('string'),
  salesAccountHierarchyGroup: attr('string'),
  requestedTestPanelNames: attr('string'),
  incompleteReportDates: attr('string'),
  completeReportDates: attr('string'),
  qualityCheck: attr('string'),
  qualityCheckDate: attr('string'),
  primaryInsurance: attr('string'),
  billingStatus: attr('string'),
  casePayerScenarios: attr('string'),
  totalCharges: attr('string'),
  adjustedCharges: attr('string'),
  receipts: attr('string'),
  balances: attr('string'),
  postingDates: attr('string'),
  primaryInsurancePlanNames: attr('string'),
  primaryInsuranceFirstBillDates: attr('string'),
  primaryInsurancePaymentPostingDates: attr('string'),
  primaryInsuranceInsurancePayments: attr('string'),
  secondaryInsurancePlanNames: attr('string'),
  secondaryInsuranceFirstBillDates: attr('string'),
  secondaryInsurancePaymentPostingDates: attr('string'),
  secondaryInsuranceInsurancePayments: attr('string'),
  patientFirstBillDates: attr('string'),
  patientPaymentPostingDates: attr('string'),
  patientPaymentAmounts: attr('string'),
  patientBalances: attr('string'),
  insuranceBalances: attr('string'),

  organizationId: attr(),
  searchParams: attr()
});
