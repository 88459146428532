/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get, semi */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { capitalize } from '@ember/string';

export default Model.extend({
  // Associations
  organization:           belongsTo('organization', { async: true }),
  webhookEndpoint:        belongsTo('webhook-endpoint', { async: true }),

  // Attributes
  eventId:                attr('string'), // actual webhook endpoint event ID
  requisitionId:          attr('string'),
  requisitionIdentifier:  attr('string'),
  eventType:              attr('string'),
  eventTimestamp:         attr('datetime'),
  sentAt:                 attr('datetime'),
  status:                 attr('string'),
  responseCode:           attr('string'),
  responseMsg:            attr('string'),
  payload:                attr('string'),

  formattedPayload: computed('payload', function() {
    let payload = this.get('payload');
    if (!payload) {
      return null;
    }
    return JSON.stringify(JSON.parse(payload), undefined, 2);
  }),

  formattedResponse: computed('responseMsg', function() {
    let res = this.get('responseMsg');
    if (!res) {
      return null;
    }
    return JSON.stringify(JSON.parse(res), undefined, 2);
  }),

  formattedEventType: computed('eventType', function() {
    let type = this.get('eventType');
    if (type === 'requisition_signed_accession') {
      return 'Req Form Verified'
    }
    return type.split('_').map((item) => capitalize(item)).join(' ')
  }),

});
