/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, quotes, ember/no-component-lifecycle-hooks, ember/no-get, max-len, ember/no-observers, no-multi-spaces, ember/no-actions-hash, object-shorthand, ember/no-jquery, semi, indent */
import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { alias } from '@ember/object/computed';
import Progress from 'shared/mixins/progress';
import Uploader from 'shared/utils/uploader';
import CurrentOrganization from 'shared/mixins/current-organization';
import layout from './template';
import $ from 'jquery';

const toArray = function(list) {
  return Array.prototype.slice.call(list || [], 0);
};

const fileFilter = new RegExp(/^(.DS_Store)/);

export default Component.extend(Progress, CurrentOrganization, {
  layout,
  classNames: ['drop-zone'],
  classNameBindings: ['active:drop-zone-activated:drop-zone-deactivated'],
  active: false,
  helpText: null,
  entityId: '',
  filenameRegex: null,
  filenameRegexErrorMessage: 'Invalid filename',
  docId: '',
  label: '',
  folders: false,
  isUploading: false,
  validateResource: false,
  createDocument: false,
  resourceValidationService: service('resource-validation'),

  validationResult: null,
  additionalValidationData: null,
  additionalValidationDataRequired: false,
  resourceEntityId: null,
  resourceEntityType: null,
  resourceGroups: alias('resourceConfiguration.resourceGroups'),
  preSelectedResources: alias('resourceConfiguration.preSelectedResources'),
  hasPreselectResources: alias('resourceConfiguration.hasPreselectResources'),
  resources: null,
  sampleIds: [],
  fileUploaded: null,

  onAfterValidation: () => {},

  flashMessages: service(),
  store: service(),
  session: service(),

  addAndValidateResources(resource) {
    this.resources.push(resource);
    this.validate();
  },

  didReceiveAttrs() {
    this.set('resources', []);
    this._super(...arguments);
    let pres = this.preSelectedResources;
    if (pres && pres.length > 0) {
      pres.forEach((resource) => {
        if (typeof this.updateResource === 'function') {
          this.updateResource(resource);
        }
        this.addAndValidateResources(resource);
      });
    }
  },

  validate() {
    let self = this;
    let validateResource = this.validateResource;
    let resources = this.resources;
    let additionalValidationData = this.additionalValidationData;
    let additionalValidationDataRequired = this.additionalValidationDataRequired;
    if (!validateResource || (additionalValidationDataRequired && !additionalValidationData)) {
      return;
    }
    let resourceValidationService = this.resourceValidationService;
    let resourceEntityType = this.resourceEntityType;
    let resourceEntityId = this.resourceEntityId;
    let resourceLabel = this.label;
    resources.forEach((resource) => {
      if (!resource.validationResult) {
        self.set('validatingFileContent', true);
        resourceValidationService.sendResourceForValidation(resource.get('id'), resourceEntityId, resourceEntityType, resourceLabel, additionalValidationData).then((validationResult) => {
          self.set('validationResult', JSON.stringify(validationResult));
          resource.set('validationResult', validationResult);
          self.set('validatingFileContent', false);
          self.onAfterValidation(resource);
        });
      }
    });
  },

  additionalValidationObserver: observer('additionalValidationData', function() {
    this.validate();
  }),

  dragOver(event) {
    event.preventDefault();
    this.set('active', true);
  },

  dragLeave(event) {
    event.preventDefault();
    this.set('active', false);
  },

  drop(event) {
    event.preventDefault();
    this.set('active', false);
    this.didDrop(event);
  },

  didDrop(event) {
    // items is supported by Chrome only
    let { items, files } = event.dataTransfer;

    // if browser is Chrome, upload actual directory structure
    if (items && items.length > -1) {
      this.addItems(items);

    // otherwise, upload all files only
    } else if (files && files.length > -1) {
      this.addFiles(files);
    }
  },

  addItems(items) {
    let numItems = items.length;

    for (let i = 0; i < numItems; i++) {
      let item = items[i].webkitGetAsEntry();

      this.addDirectoryContents([item]);
    }
  },

  addFiles(files) {
    let numFiles = files.length;
    let messages = this.flashMessages;

    for (let i = 0; i < numFiles; i++) {
      if (isPresent(files[i].type)) {
        this._uploadFile(files[i]);

      } else {
        messages.error('Uploading folders is not supported with this browser. Please use Chrome to upload folders.');
        return;
      }
    }
  },

  addDirectoryContents(items, resourceGroup) {
    items.forEach((item) => {
      if (item.isDirectory) {
        this.addDirectory(item, resourceGroup);
      } else {
        item.file((file) => {
          this._uploadFile(file, resourceGroup);
        });
      }
    });
  },

  addDirectory(directory, resourceGroup) {
    let store = this.store;
    let messages = this.flashMessages;
    let entityId = this.entityId;
    let updateResourceGroup = this.updateResourceGroup;

    let newResourceGroup = store.createRecord('resource-group', {
      name: directory.name,
      parent: resourceGroup,
      activityId: entityId
    });

    newResourceGroup.save()
      .then((newResourceGroup) => {
        if (isPresent(resourceGroup)) {
          resourceGroup
            .get('resourceGroups')
            .then((resourceGroups) => resourceGroups.addObject(newResourceGroup));

        } else if (typeof updateResourceGroup === 'function') {
          updateResourceGroup(newResourceGroup);
        }

        this.getDirectoryEntries(directory, newResourceGroup);
      })
      .catch((response) => {
        messages.error(`Failed to upload folder: ${directory.name}`);
        console.error(`Failed to upload folder: ${response}`);
      });
  },

  getDirectoryEntries(directory, resourceGroup) {
    let self = this;
    let entries = [];
    let directoryReader = directory.createReader();

    let readEntries = function() {
      directoryReader.readEntries((results) => {
        if (!results.length) {
          self.addDirectoryContents(entries, resourceGroup);
        } else {
          entries = entries.concat(toArray(results));
          readEntries();
        }
      }, self.fileSystemErrorHandler.bind(self));
    };

    readEntries();
  },

  fileSystemErrorHandler(error) {
    console.error('FileSystem API error', error.message);
    this.flashMessages.error('Failed to upload directory structure');
  },

  runFileUploaded(resource) {
    if (typeof this.fileUploaded === 'function') {
      this.fileUploaded(resource.name);
    }
  },

  _uploadFile(file, resourceGroup) {
    let messages = this.flashMessages;
    let filenameRegex = this.filenameRegex;
    let filenameRegexErrorMessage = this.filenameRegexErrorMessage;
    let fileUpload = this;
    let entityId = this.entityId;
    let docId = this.docId;
    let label = this.label;
    let createDocument = this.createDocument;
    let store = this.store;
    let self = this;
    fileUpload.set('isUploading', true);

    if (fileFilter.test(file.name)) {
      fileUpload.set('isUploading', false);
      return;
    }

    if (filenameRegex && !filenameRegex.test(file.name)) {
      messages.error(filenameRegexErrorMessage);
      fileUpload.set('isUploading', false);
      return;
    }

    let uploader = Uploader.create({
      entityId,
      docId,
      resourceGroup,
      store,
      label,
      createDocument
    });

    uploader.on('didUpload', function(resource) {
      fileUpload.set('isUploading', false);
      fileUpload.updateProgress(0);
      if (typeof fileUpload.attrs.setUploading === 'function') {
        fileUpload.attrs.setUploading(false);
      }
      if (isPresent(resourceGroup)) {
        resourceGroup
          .get('resources')
          .then((resources) => {
            resources.addObject(resource);
            resource.save(); // persist versionId etc returned from AWS
          });
      } else if (typeof fileUpload.attrs.updateResource === 'function') {
        fileUpload.attrs.updateResource(resource);
      }
      if (typeof fileUpload.attrs.postProcessing === 'function') {
        fileUpload.attrs.postProcessing(file);
      }
      self.addAndValidateResources(resource);
      self.runFileUploaded(resource);
    });

    uploader.on('didFail', function() {
      fileUpload.set('isUploading', false);
      fileUpload.updateProgress(0);
    });

    uploader.on('progress', function(e) {
      fileUpload.updateProgress(e.percent);
      fileUpload.session.refreshSession();
    });

    if (!isEmpty(file)) {
      if (typeof fileUpload.attrs.setUploading === 'function') {
        fileUpload.attrs.setUploading(true);
      }
      uploader.upload(file); // Uploader will send a sign request then upload to S3
    }
  },
  actions: {
    upload: function() {
      let input = $(this.element).find('input');
      input.val('');
      input.trigger('click');
    },

    validateOnSelect(resource) {
      this.addAndValidateResources(resource);
    },

    useFiles(files) {
      this.saveFiles(files);
    },

    useBasespaceFiles(files) {
      const entityId = this.entityId;
      const label = this.label;
      const createDocument = this.createDocument;

      files.forEach((file) => {
        this
          .store
          .createRecord('resource', {
            entityId,
            contentType: file.contentType,
            label,
            path: file.name,
            sourceUrl: file.contentUrl,
            createDocument
          })
          .save()
          .then((resource) => {
            if (typeof this.updateResource === 'function') {
              this.updateResource(resource);
            }

            this.addAndValidateResources(resource);
            this.runFileUploaded(resource);
          })
          .catch(() => {
            this.flashMessages.error(`Failed to process: ${file.name}`);
          });
      });
    },

    saveFiles(files) {
      const entityId = this.entityId;
      const contentType = 'application/octet-stream';
      const label = this.label;

      files.forEach((file) => {
        this
          .store
          .createRecord('resource', {
            entityId,
            contentType,
            label,
            path: file.name,
            copy: file.id
          })
          .save()
          .then((resource) => {
            if (typeof this.updateResource === 'function') {
              this.updateResource(resource);
            }

            this.addAndValidateResources(resource);
            this.runFileUploaded(resource);
          })
          .catch(() => {
            this.flashMessages.error(`Failed to process: ${file.name}`);
          });
      });
    }
  },
});
