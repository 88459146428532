/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash, ember/no-get, quotes */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import config from 'ember-get-config';
import layout from './template';
import fetch from 'fetch';

export default Component.extend({
  layout,
  flashMessages: service(),
  session: service('current-session'),

  store: service(),
  currentSession: service(),

  actions: {
    changePassword() {
      let token = this.currentSession.token;
      let userId = this.get('currentSession.currentUser.id');
      fetch(
        `${config.api_endpoint}/api/v2/users/${userId}/change_password`,
        {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
        .then(() => {
          this.flashMessages.success('Check your email for the next steps to change your password.');
        })
        .catch(() => {
          this.flashMessages.error('Error encountered when trying to change your password.');
        });
    }
  }

});
