/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, ember/use-brace-expansion, ember/no-get, max-len, ember/no-component-lifecycle-hooks, ember/no-actions-hash */
import {
  alias,
  and,
  not,
  or,
  notEmpty,
  equal,
  mapBy
} from '@ember/object/computed';
import { isNone, isEmpty } from '@ember/utils';
import { assert } from '@ember/debug';
import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import ENV from 'ember-get-config';
import layout from './template';
import EnforceSampleIdentifier from 'shared/mixins/enforce-sample-identifier';

const TIMEOUT = ENV.environment === 'test' ? 0 : 250;

export default Component.extend(EnforceSampleIdentifier, {
  layout,
  flashMessages: service(),
  store: service(),
  settings: service(),

  _setSamplesIdentifiers() {},

  unregister() {},

  disabledSamplesGroup: false,
  identifierProvided: false,
  fulfilled: false, // set in _setInitialState

  cancelDisabled: alias('isSaving'),
  collectionDateTime: alias('sample.collectionDateTime'),
  dateReceived: or('sample.dateReceived', 'sample.receivedDateTime'),
  hasSampleGroups: notEmpty('sampleGroups'),
  interpolationConfig: alias('selectedSampleGroup.sampleIdentifierInterpolation'),
  isFormV1Template: alias('requisitionTemplate.isFormV1Template'),
  isReceived: alias('sample.received'),
  isSaving: alias('sample.isSaving'),
  isInvalid: not('isValid'),
  isValid: and('sample.identifier', 'container', 'position', 'customRequirementsSet'),
  oneSampleGroupOption: equal('sampleGroups.length', 1),
  patient: alias('requisition.patient'),
  portalOrder: alias('settings.portalOrder'),
  position: alias('sampleState.position'),
  requiredGlobalCustomAttributes: mapBy('requiredCustomAttributes', 'globalCustomAttribute'),
  requisitionTemplate: alias('requisition.requisitionTemplate'),
  sampleState: alias('sample.sampleStates.firstObject'),
  saveDisabled: or('isSaving', 'isInvalid'),
  selectedSampleGroup: alias('sample.requisitionTemplateSamplesGroup'),
  container: alias('sampleState.container'),
  isReceivedDateValid: false,
  isCollectionDateValid: false,
  plateConfiguration: { containerTypes: ['Tube', '96-Well', '384-Well'] },

  customRequirementsSet: computed(
    'collectionDateTime',
    'dateReceived',
    'isCollectionDateValid',
    'isReceived',
    'isReceivedDateValid',
    'requiredGlobalCustomAttributes.@each.id',
    'sample.entityCustomAttributes.@each.value',
    'sample.sampleStates.firstObject.label',
    'selectedSampleGroup',
    function() {
      const selectedSampleGroup = this.selectedSampleGroup;

      if (selectedSampleGroup) {

        if (selectedSampleGroup.requiredCollectionDatetime && isEmpty(this.collectionDateTime)) {
          return false;
        }

        if (!this.isCollectionDateValid) {
          return false;
        }

        if (this.isReceived && (isEmpty(this.dateReceived) || !this.isReceivedDateValid)) {
          return false;
        }

        if (!this.get('sample.sampleStates.firstObject.label')) {
          return false;
        }

        const requiredGlobalCustomAttributes = this.requiredGlobalCustomAttributes.mapBy('id');
        const missingRequiredCustomAttributes = this.sample.get('entityCustomAttributes').filter((eca) => requiredGlobalCustomAttributes.includes(eca.get('globalCustomAttribute.id')) && isEmpty(eca.get('value')));

        if (missingRequiredCustomAttributes.length) {
          return false;
        }
      }

      return true;
    }),

  enforceSingleIdentifier: computed('isFormV1Template', 'requisitionTemplate', 'selectedSampleGroup', function() {
    if (this.isFormV1Template) {
      return this.selectedSampleGroup.get('enforceSingleIdentifier');
    } else {
      return this.requisitionTemplate.get('enforceSingleIdentifier');
    }
  }),

  reqTemplate: computed('requisitionTemplate.template', function() {
    let template = this.get('requisitionTemplate.template');
    if (isNone(template)) {
      return null;
    }

    return JSON.parse(template);
  }),

  requiredCustomAttributes: computed('portalOrder', 'selectedSampleGroup.requisitionTemplateGlobalCustomAttributes.@each.{lab,portal}', 'this.selectedSampleGroup', function() {
    return this
      .selectedSampleGroup
      .get('requisitionTemplateGlobalCustomAttributes')
      .filterBy(this.portalOrder ? 'portal' : 'lab', 'required');
  }),

  sampleGroups: computed(
    'requisitionTemplate', 'requisitionTemplate.requisitionTemplateSamplesGroups', function() {
      let requisitionTemplate = get(this, 'requisitionTemplate');

      if (isNone(requisitionTemplate)) {
        return [];
      }

      const requisitionTemplateSampleGroups = requisitionTemplate.get('requisitionTemplateSamplesGroups');

      if (isNone(requisitionTemplateSampleGroups)) {
        return [];
      }
      return requisitionTemplateSampleGroups;
    }),

  sampleTypes: computed(
    'requisitionTemplate.projectRequisitionTemplateAssociations',
    'requisitionTemplate.name',

    function() {
      let currentReqTemplateAssociation = this._getReqTemplateAssociation();

      if (isNone(currentReqTemplateAssociation)) {
        return [];
      }

      let filteredSampleTypes = [];

      const rules = currentReqTemplateAssociation.get('requisitionSampleRouting.configuration');

      if (rules) {
        filteredSampleTypes = rules.map(rule => rule.sampleType);
      }
      return filteredSampleTypes;
    }),

  _getReqTemplateAssociation() {
    let projectRequisitionTemplateAssociations = this.get('requisitionTemplate.projectRequisitionTemplateAssociations');
    let projectId = this.get('requisition.projectId');

    if (projectRequisitionTemplateAssociations == null) {
      return [];
    }
    let currentReqTemplateAssociation = projectRequisitionTemplateAssociations.findBy('projectId', projectId);
    return currentReqTemplateAssociation;
  },

  _getSampleIndexByGroup(groupName) {
    const samples = get(this, 'requisition.samples');

    return samples.filterBy('groupName', groupName).length;
  },

  didReceiveAttrs() {
    this._super(...arguments);
    this._setInitialState.perform();
  },

  _setInitialState: task(function *() {
    yield timeout(TIMEOUT);

    assert('`requisition` is a required attribute', this.requisition);
    assert('`organization` is a required attribute', this.organization);

    const requisition = this.requisition;
    const requisitionTemplate = requisition.get('requisitionTemplate');
    const sampleGroup = this.sampleGroup;
    let identifier = requisitionTemplate.get('enforceSingleIdentifier') ? requisition.identifier : '';

    // Possible to pass in a user-provided value for sample identifier on init
    if (this.identifier) {
      identifier = this.identifier;
      this.set('identifierProvided', true);
    }

    const sample = this.store.createRecord('sample', {
      identifier,
      organization: this.organization,
      requisition
    });

    sample.get('sampleStates').pushObject(this.store.createRecord('sample-state'));

    if (sampleGroup) {
      sample.set('requisitionTemplateSamplesGroup', sampleGroup);
      this.set('disabledSamplesGroup', true);
    }

    this.set('sample', sample);

    if (sampleGroup) {
      const sampleIndex = this._getSampleIndexByGroup(sampleGroup.groupName);
      this._setSampleIdentifier(sample, sampleIndex);
    }
    this.set('fulfilled', true);
  }),

  _removeUnsavedEntityCustomAttributes(model) {
    let records = model.entityCustomAttributes.toArray();
    records.forEach((customAttribute) => {
      if (customAttribute && customAttribute.isNew) {
        customAttribute.unloadRecord();
      }
    });
  },

  actions: {
    cancel() {
      this.sampleState.rollbackAttributes();
      this.sample.rollbackAttributes();
      this.onCancel(this.sample);
    },

    changeGroup(groupName) {
      let sampleGroups = this.sampleGroups;
      let group = sampleGroups.findBy('groupName', groupName);

      this.set('sample.requisitionTemplateSamplesGroup', group);

      const sampleIndex = this._getSampleIndexByGroup(groupName);

      this._setSampleIdentifier(this.sample, sampleIndex);
    },

    create(model) {
      let messages = this.flashMessages;
      let { sample, sampleState } = model;

      if (!sample.get('received')) {
        if (sample.get('dateReceived')) {
          sample.set('dateReceived', null);
        }
        if (sample.get('receivedDateTime')) {
          sample.set('receivedDateTime', null);
        }
      }

      sample.save().then(
        (/*response*/) => {
          sampleState.rollbackAttributes(); // New sample state is returned from server
          messages.success('Sample created');
          this._removeUnsavedEntityCustomAttributes(sample);
          this.onSampleAdded(sample);
        },
        (xhr) => {
          messages.error(`Failed to create sample. ${xhr.errors && xhr.errors.base || ''}`);
        }
      );
    },

    setPlate(params) {
      let container = params.container;

      this.set('container', container);
      this.set('position', container.get('positions').shift());
    },

    onValidateReceivedDate(isValid) {
      this.set('isReceivedDateValid', isValid);
    },

    onValidateCollectionDate(isValid) {
      this.set('isCollectionDateValid', isValid);
    }
  }
});
