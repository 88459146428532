/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/require-super-in-lifecycle-hooks, max-len, ember/no-actions-hash */
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import moment from 'moment';
import layout from './template';

const maybeDowncase = function(value) {
  if (value === 'M' || value === 'D' || value === 'H') {
    return value.toLowerCase();
  }

  return value;
};

export default Component.extend({
  layout,

  classNames: ['cl-datetimepicker'],

  dateTime: null,
  format: null,
  dateonly: false,
  readOnly: false,
  disabled: false,
  autofill: false,
  enableValidationMessage: true,

  onChange: () => {},
  onValidate: () => {},

  init() {
    this._super(...arguments);

    assert('Missing required attribute: `format`', this.format);
  },

  didReceiveAttrs() {
    if (this.dateTime) {
      this.set('dateValue', moment(this.dateTime).format(this.datePlaceholder));
      this.set('timeValue', moment(this.dateTime).format(this.timePlaceholder.replace('am|pm', 'a')));
    }
  },

  dateOptions: computed('format', function() {
    let delimiter = '-';
    let datePattern = ['Y', 'm', 'd'];

    const match = this.format.match(/([A-Z]+)(.)([A-Z]+).([A-Z]+)/);

    if (match) {
      delimiter = match[2];
      datePattern = [
        maybeDowncase(match[1][0]),
        maybeDowncase(match[3][0]),
        maybeDowncase(match[4][0])
      ];
    }

    return {
      date: true,
      delimiter,
      datePattern
    };
  }),

  datePlaceholder: computed('format', function() {
    return this.format.split(' ')[0];
  }),

  timeOptions: computed('format', function() {
    let delimiters = [':'];
    let timeFormat = '24';
    let timePattern = ['h', 'm'];

    const match = this.format.match(/([Hh]+):([m]+)[:]?([s]+)?.?(a)?/);

    if (match) {
      timeFormat = match[4] ? '12' : '24';
      timePattern = [
        maybeDowncase(match[1][0]),
        maybeDowncase(match[2][0])
      ];

      if (match[3]) {
        delimiters.push(':');
        timePattern.push(match[3][0]);
      }

      if (match[4]) {
        delimiters.push(' ');
        timePattern.push(match[4][0]);
      }
    }

    return {
      time: true,
      delimiters,
      timeFormat,
      timePattern
    };
  }),

  timePlaceholder: computed('format', function() {
    const sections = this.format.split(' ');

    if (sections.length === 2) {
      return sections[1];
    }

    return [
      sections[1],
      'am|pm'
    ].join(' ');
  }),

  displayValidationMessage: computed('dateTime', 'minDate', 'maxDate', 'dateValue', 'timeValue', 'enableValidationMessage', function() {
    const { format, minDate, maxDate, dateTime, dateValue, timeValue } = this;

    if (!this.enableValidationMessage) {
      return null;
    }

    let message = null;

    if (dateTime) {
      const udateTime = moment(dateTime, format).toDate();
      const uminDate = moment(minDate, format).toDate();
      const umaxDate = moment(maxDate, format).toDate();

      if (uminDate && udateTime < uminDate) {
        message = `Should be after ${moment(uminDate).format(format)}`;
      } else if (umaxDate === 'current' && udateTime > moment().add(15, 'minutes')) {
        message = `Should be before ${moment().format(format)}`;
      } else if (umaxDate && udateTime > umaxDate) {
        message = `Should be before ${moment(umaxDate).format(format)}`;
      }
    } else if (dateValue || timeValue) {
      message = `Invalid date format (${format})`;
    }

    message = this.onValidate(null === message);

    return message;
  }),

  _updateDateTime() {
    const formatRegexp = new RegExp(this.format.replace(/[A-Z]/ig, '.'));
    const dateTime = this.dateonly ? this.dateValue : `${this.dateValue} ${this.timeValue}`;

    const unformatted = formatRegexp.test(dateTime) ? moment(dateTime, this.format).toDate() : null;

    this.set('dateTime', unformatted);
    this.onChange(unformatted);
  },

  actions: {
    maybeSetDate(event) {
      const isEmpty = !event.currentTarget.value || event.currentTarget.value.length === 0;

      let newValue = null;

      if (this.autofill && isEmpty) {
        if (['Tab', 't'].includes(event.key)) {
          newValue = moment().format(this.datePlaceholder);
        }

        if (event.key === 'y') {
          newValue = moment().subtract(1, 'day').format(this.datePlaceholder);
        }
      }

      if (!newValue) {
        return;
      }

      this.set('dateValue', newValue);
      this._updateDateTime();
    },

    maybeSetTime(event) {
      const isEmpty = !event.currentTarget.value || event.currentTarget.value.length === 0;

      let newValue = null;

      if (this.autofill && isEmpty) {
        if (['Tab', 'n'].includes(event.key)) {
          newValue = moment().format(this.timePlaceholder.replace('am|pm', 'a'));
        }
      }

      if (!newValue) {
        return;
      }

      this.set('timeValue', newValue);
      this._updateDateTime();
    },

    updateDate(value) {
      this.set('dateValue', value);
      this._updateDateTime();
    },

    updateTime(value) {
      this.set('timeValue', value);
      this._updateDateTime();
    },
  }
});
