/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, object-shorthand, ember/no-get, space-before-function-paren, ember/no-actions-hash */
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import layout from './template';
import { computed } from '@ember/object';

export default Component.extend({
  layout,

  currentSession: service(),

  showSignatureModal: false,
  showConfirmationModal: false,
  requisition: null,
  sign: undefined,
  canSign: alias('requisition.permissions.signable'),
  canPortalSign: alias('requisition.canPortalSign'),
  isSSO: alias('currentSession.data.isSSO'),
  isMFA: alias('currentSession.data.isMFA'),

  closeModal: function() {
    this.set('signature', null);
    this.set('showSignatureModal', false);
    this.set('showConfirmationModal', false);
  },

  _sign() {
    let sign = this.get('sign');
    if (typeof sign === 'function') {
      let signature = this.get('signature');
      let requisition = this.get('requisition');
      sign(requisition, signature);
      this.closeModal();
    }
  },

  verifyDisabled: computed('signature', 'isSSO', 'isMFA', function () {
    if (this.isSSO || this.isMFA) {
      return true;
    }

    let signature = this.signature || '';
    return signature.trim().length < 8;
  }),

  actions: {
    showModal: function() {
      if (this.passwordRequired && (this.isSSO || this.isMFA)) {
        this.set('showConfirmationModal', true);
      } else {
        this.set('showSignatureModal', true);
      }
    },
    cancel: function() {
      this.closeModal();
    },
    sign: function() {
      this._sign();
    }
  }

});
