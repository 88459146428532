import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default class RequisitionImportModel extends Model {
  // Associations
  @belongsTo('organization', { async: false }) organization;
  @belongsTo('user', { async: false }) user;
  @belongsTo('resource', { async: false }) resource;  // this is the primary import resource
  @hasMany('resource', { async: false }) resources;   // these are possibly 1..many individual import resources, derived from the primary resource

  // Attributes
  @attr('string') status;
  @attr('string') error;
  @attr('date') createdAt;
  @attr('date') updatedAt;
}
