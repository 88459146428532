/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';

export default Model.extend({
  // Associations
  organization:                    belongsTo('organization'),
  printTemplate:                   belongsTo('print-template'),
  requisitionTemplateAssociations: hasMany('requisition-template-association', { async: true }),
  requisitionTemplateGlobalCustomAttributes: hasMany('requisition-template-global-custom-attribute', { async: false }),
  requisitionTemplateSamplesGroups: hasMany('requisition-template-samples-group', { async: false }),
  projectRequisitionTemplateAssociations: hasMany('project-requisition-template-association', { async: false }),

  // Attributes
  name:           attr('string'),
  description:    attr('string'),
  portalSignatureRequired: attr('boolean'),
  autoVerification: attr('boolean'),
  templateType:   attr('string'),
  componentName:  attr('string'),
  enforceSingleIdentifier: attr('boolean'),
  patientConsentVersionId: attr('number'),
  medicalConsentVersionId: attr('number'),
  template:       attr(),
  sampleData:     attr(),
  projectReqAssociationProjectId: attr('number'),
  allowSampleCustomGlobalId: attr('boolean', { defaultValue: false }),
  exportUrl: attr('string'),

  isFormV1Template: equal('templateType', 'form_v1'),
  isLegacyTemplate: equal('templateType', 'legacy'),

  templateOptions: computed('template', function() {
    const template = this.get('template');
    if (isNone(template)) {
      return null;
    }
    return JSON.parse(template);
  })
});

export const DEFAULT_REQUISITION_SECTIONS_ORDER = [
  'requisition-information',
  'supplemental-documents',
  'ehr-documents',
  'samples',
  'tests',
  'patient-information',
  'billing-information',
  'medication-information',
  'icd10codes-information',
  'secondary-service',
  'patient-consent',
  'medical-consent',
];
