/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/no-actions-hash, no-multi-spaces, ember/no-get */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { get, computed } from '@ember/object';
import layout from './template';
import { capitalize } from '@ember/string';

export default Component.extend({
  layout,

  flashMessages: service(),

  tagName: '',

  entity: undefined,
  redirectEntityId: undefined,
  entityName: 'Entity',
  useDelete: false,
  notArchivableMessage: null,
  notArchivableRoute: null,
  notArchivableRouteName: null,

  afterArchive() {},
  afterUnarchive() {},

  confirmationMessage: computed('entityName', function() {
    return `Do you want to delete this ${this.entityName}?`;
  }),

  actions: {
    archive() {
      let archived   = get(this.entity, 'archived');

      let promise = null;
      if (this.useDelete) {
        if (this.entity.archived) {
          this.entity.set('archivedAt', 0);
          promise = this.entity.save();
        } else {
          this.entity.set('archivedAt', 1);
          promise = this.entity.save();
        }
      } else {
        this.entity.toggleProperty('archived');
        promise = this.entity.save();
      }

      promise.then(
        () => {
          if (!archived && !this.entity.archived) {
            this.flashMessages.error(`${capitalize(this.entityName)} can not be deleted`);
          } else if (this.entity.archived) {
            this.flashMessages.success(`${capitalize(this.entityName)} has been deleted`);
            this.afterArchive();
          } else {
            this.flashMessages.success(`${capitalize(this.entityName)} has been restored`);
            this.afterUnarchive();
          }
        },
        () => {
          this.entity.rollbackAttributes();
          if (this.entity.archived) {
            this.flashMessages.error(`Failed to restore ${this.entityName}`);
          } else {
            this.flashMessages.error(`Failed to delete ${this.entityName}`);
          }
        }
      );

      return false;
    }
  }
});
