/* eslint-disable ember/no-new-mixins, object-shorthand, ember/no-get, key-spacing, quotes */
import { notEmpty } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  isUploading: false,
  hasResource: notEmpty('model.resourceUrl'),
  flashMessages: service(),

  actions: {
    setUploading: function(boolean) {
      this.set('isUploading', boolean);
    },

    updateResource: function(resource) {
      resource.save().then(() => {
        this.get('model').setProperties({
          resourceId:        resource.get('id'),
          resourceVersion:   resource.get('version'),
          resourceUrl:       resource.get('publicUrl'),
          resourceName:      resource.get('name'),
          resourceRemoteUrl: resource.get('remoteUrl')
        });
      });
    },

    deleteResource(model) {
      let resource = this.store.peekRecord('resource', model.get('resourceId'));
      let messages = this.get('flashMessages');

      if (resource && confirm("Are you sure?")) {
        resource.destroyRecord().then(
          () => {
            messages.success('Resource deleted');
            model.setProperties({
              resourceId:        undefined,
              resourceVersion:   undefined,
              resourceUrl:       undefined,
              resourceName:      undefined,
              resourceRemoteUrl: undefined
            });
          },
          () => messages.error('Failed to delete resource')
        );
      }
    }
  }
});
