/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { hasMany, attr } from '@ember-data/model';
import { getProperties, computed } from '@ember/object';
import { isBlank } from '@ember/utils';

export default Model.extend({

  testPanels:     hasMany('test-panel', { async: false }),

  genericName:             attr('string'),
  brandName:               attr('string'),
  name:                    attr('string'),
  route:                   attr('string'),
  doseQuantity:            attr('number'),
  doseUnit:                attr('string'),
  rxcui:                   attr('string'),
  quantity:                attr('string'),
  qualitativeDistinction: attr('string'),
  strengths:               attr('string'),
  isMultiIngredient:       attr('boolean'),

  nameWithDose: computed('{quantity,name,dose}', function() {
    const { name, dose, quantity } = getProperties(this, 'name', 'dose', 'quantity');
    let medName = name;

    if (!isBlank(dose)) {
      medName = `${medName} (${dose})`;
    }

    if (!isBlank(quantity)) {
      medName = `${quantity} ${medName}`;
    }

    return medName;
  }),

  dose: computed('doseQuanity', 'doseQuantity', 'doseUnit', function() {
    const { doseQuantity, doseUnit } = getProperties(this, 'doseQuantity', 'doseUnit');

    return [doseQuantity, doseUnit].compact().join(' ');
  })

});
